import React from 'react';
import { Link, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { addHotspot } from '../utils/imageUrl';
import { defaultSerializers } from '../utils/serializers';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AlbumLink from '../components/albumLink';

const Music = ({ data }) => {
  const albums = data.allSanityAlbum.nodes;
  return (
    <Layout
      dominantColour={
        data.sanityPage.bannerImage.asset.metadata.palette.dominant.background
      }
      textColour={
        data.sanityPage.bannerImage.asset.metadata.palette.dominant.title
      }
      headerImage={addHotspot(
        data.sanityPage.bannerImage.asset.fluid.src,
        data.sanityPage.bannerImage.hotspot
      )}
    >
      <SEO title="Music" />
      <article className="page-content page-content--music">
        <header>
          <h1>{data.sanityPage.title}</h1>
        </header>
        <BlockContent
          blocks={data.sanityPage._rawIntro}
          serializers={defaultSerializers}
        />
        <h2>Albums:</h2>
        {albums.map(album => {
          return <AlbumLink key={album.id} {...album} released />;
        })}
      </article>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    sanityPage(slug: { current: { eq: "music" } }) {
      title
      _rawIntro(resolveReferences: { maxDepth: 5 })
      bannerImage {
        hotspot {
          x
          y
        }
        asset {
          fluid(maxWidth: 1600, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
          metadata {
            palette {
              dominant {
                background
              }
            }
          }
        }
      }
    }
    allSanityAlbum {
      nodes {
        id
        snippet
        tracks {
          title
          song {
            title
          }
          sources {
            asset {
              url
              extension
            }
          }
          appleMusicURL
          spotifyURL
        }
        albumCover {
          asset {
            fixed(width: 200) {
              ...GatsbySanityImageFixed
            }
          }
        }
        appleMusicURL
        bandcampURL
        slug {
          current
        }
        spotifyURL
        title
      }
    }
  }
`;

export default Music;
