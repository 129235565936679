import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { actions } from '../store/slices/player';
import Img from 'gatsby-image';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { FaCompactDisc, FaBandcamp } from 'react-icons/fa';

export const Album = ({
  albumCover,
  appleMusicURL,
  bandcampURL,
  spotifyURL,
  title,
  slug,
  snippet,
  tracks = [],
  coverWidth = 200,
  released = false,
  playAlbumFrom = (i: number) => {},
  ...rest
}) => {
  const albumUrl = `/music/album/${slug.current}/`;

  return (
    <div className="album album--link">
      <div
        style={{
          width: coverWidth,
          height: coverWidth,
          backgroundColor: '#ccc',
        }}
      >
        {albumCover && (
          <Link to={albumUrl}>
            <Img
              className="album__cover"
              style={{ flexBasis: `${coverWidth}px` }}
              fixed={albumCover.asset.fixed}
            />
          </Link>
        )}
      </div>
      <div className="album__details">
        <h2 className="album__title">
          <Link to={albumUrl}>{title}</Link>
        </h2>
        <p>
          {snippet} {released && 'Released: 2019 - '}
          {tracks.length} tracks
        </p>

        {released && (
          <>
            <Link className="album__view-details" to={albumUrl}>
              <FaCompactDisc color="#fff" size={12} /> View Album Details
            </Link>
            <a
              className="album__play"
              href={bandcampURL}
              rel="noopener"
              target="_blank"
            >
              <FaBandcamp color="#fff" size={12} /> Buy on Bandcamp
            </a>
            <button
              className="album__play"
              onClick={() => {
                playAlbumFrom(0);
              }}
            >
              ▶ Play
            </button>{' '}
          </>
        )}
      </div>
      {/* <div className="album__purchase">
        Available on: <a href={bandcampURL}>Bandcamp</a>,{" "}
        <a href={appleMusicURL}>Apple Music</a>,{" "}
        <a href={spotifyURL}>Spotify</a>,{" "}
        <a href="https://play.google.com/music/preview/B7z5fswpdrtknydyf6q34vo2k2u?t=Love_and_Gold_-_Nicolette_Macleod">
          Google Play
        </a>{" "}
        and <a href="https://amzn.to/2D5uUl3">Amazon</a>
      </div> */}
    </div>
  );
};

export default compose(
  connect(({ player }) => ({ player }), { replaceQueue: actions.replaceQueue }),
  withHandlers({
    playAlbumFrom: ({ replaceQueue, tracks }) => (queuePosition = 0) => {
      const formattedTracks = tracks.map(({ title, sources }) => ({
        title,
        sources: sources.map(s => get(s, 'asset.url')),
      }));

      replaceQueue({ tracks: formattedTracks, queuePosition });
    },
  })
)(Album);
